import { BreakpointObserver } from '@angular/cdk/layout';

export const environment = {
  env: 'bob',
  name: 'Bob de Bot',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCI1ZtYEz3myRupKXwkgZ_f-pLthoC752k',
    authDomain: 'hallo-bot.firebaseapp.com',
    databaseURL: 'https://hallo-bot.firebaseio.com',
    projectId: 'hallo-bot',
    storageBucket: 'hallo-bot.appspot.com',
    messagingSenderId: '82103981851',
    appId: '1:82103981851:web:8cc527dbf1280df8b025be',
  },
  functionsUrl: 'https://us-central1-hallo-bot.cloudfunctions.net',
  background: {
    profile:
      'https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_ProfielBanner_130120-01.png?alt=media&token=cb1797ba-119c-465f-802c-be025d98b6c4',
  },
  login: {
    video:
      'https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/background%20%2FFixed_Bob_de_Bot_banner.mp4?alt=media&token=b0034d0c-e8ad-41d8-b541-c1f77a9d7c29',
    image:
      'https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/images%2FBob_de_Bot.png?alt=media&token=c955a823-42d1-40d5-8e5f-f27de53d6670',
  },
  loginMethod: {
    phone: false,
    email: true,
  },
  routes: [
    'login',
    'registration',
    'tasks',
    'detail',
    'createTask',
    'editTask',
    'volunteers',
    'tags',
    'settings',
    'posts',
  ],
  phone: {
    nl: '+316',
    us: '+1',
  },
  // loginMethod: {
  //   phone: true,
  //   email: false
  // }
};
